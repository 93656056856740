"use strict";

(function (window, $, undefined) {
  // 'use strict';
  // window.ua = {};
  // var ua = window.ua;
  var ua = {};
  ua.name = navigator.userAgent.toLowerCase();
  ua.isIE = ua.name.indexOf("msie") >= 0 || ua.name.indexOf("trident") >= 0;
  ua.isiPhone = ua.name.indexOf("iphone") >= 0;
  ua.isiPod = ua.name.indexOf("ipod") >= 0;
  ua.isiPad = ua.name.indexOf("ipad") >= 0;
  ua.isiOS = ua.isiPhone || ua.isiPod || ua.isiPad;
  ua.isAndroid = ua.name.indexOf("android") >= 0;
  ua.isTablet = ua.isiPad || ua.isAndroid && ua.name.indexOf("mobile") < 0;

  if (ua.isIE) {
    ua.verArray = /(msie|rv:?)\s?([0-9]{1,})([\.0-9]{1,})/.exec(ua.name);

    if (ua.verArray) {
      ua.ver = parseInt(ua.verArray[2], 10);
    }
  }

  if (ua.isiOS) {
    ua.verArray = /(os)\s([0-9]{1,})([\_0-9]{1,})/.exec(ua.name);

    if (ua.verArray) {
      ua.ver = parseInt(ua.verArray[2], 10);
    }
  }

  if (ua.isAndroid) {
    ua.verArray = /(android)\s([0-9]{1,})([\.0-9]{1,})/.exec(ua.name);

    if (ua.verArray) {
      ua.ver = parseInt(ua.verArray[2], 10);
    }
  }

  if (ua.isIE) {
    $("body").addClass("ie ie_" + ua.ver);
  }

  if (ua.isiPhone) {
    $("body").addClass("iPhone");
  }

  if (ua.isiPod) {
    $("body").addClass("iPod");
  }

  if (ua.isiPad) {
    $("body").addClass("iPad");
  }

  if (ua.isiOS) {
    $("body").addClass("iOS iOS_" + ua.ver);
  }

  if (ua.isAndroid) {
    $("body").addClass("android android_" + ua.ver);
  }

  if (ua.isTablet) {
    $("body").addClass("tablet");
  }

  function os() {
    if (ua.name.indexOf("mac") != -1) {
      return "mac";
    } else if (ua.name.indexOf("windows") != -1) {
      return "win";
    } else if (ua.name.indexOf("linux") != -1) {
      return "linux";
    } else if (ua.name.indexOf("cros") != -1) {
      return "cros";
    } else {
      return false;
    }
  }

  var os = os();
  ua.os = os;
  $("body").addClass(os);

  function browser() {
    if (ua.name.indexOf("opera") != -1) {
      return "opera";
    } else if (ua.name.indexOf("edg") != -1) {
      return "edge";
    } else if (ua.name.indexOf("chrome") != -1) {
      return "chrome";
    } else if (ua.name.indexOf("safari") != -1) {
      return "safari";
    } else if (ua.name.indexOf("gecko") != -1) {
      return "gecko";
    } else {
      return false;
    }
  }

  var browser = browser();
  ua.browser = browser;
  $("body").addClass(browser);
})(void 0, jQuery);