"use strict";

(function ($) {
  var winW = window.innerWidth;
  var winH = $(window).height();
  var is_fullmode = false;
  var topPos = 0;
  var beforTopPos = 0;
  if (winW > 768) is_fullmode = true;
  var isSP = false;
  var ua = navigator.userAgent;
  if (ua.match(/(iPhone|iPad|iPod|Android)/)) isSP = true;
  var isScrolling = false;
  var isAvailMenuBtn = true; //メニュー開閉中のフラグ

  var isParticle = false; //パーティクル起動

  var hitEvent;
  var transitionEnd = 'oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend';
  var animationEnd = 'webkitAnimationEnd oanimationend msAnimationEnd animationend';

  if (isSP) {
    $('html').addClass('SP');
    hitEvent = 'touchstart';
  } else {
    $('html').addClass('PC');
    hitEvent = 'click';
  }

  if (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') == -1 || ua.indexOf('iPad') > 0 || ua.indexOf('Kindle') > 0 || ua.indexOf('Silk') > 0) {
    $('html').addClass('Tablet');
  } //iOSでスクロール無効対策


  var movefun = function movefun(event) {
    event.preventDefault();
  }; //メニュー開閉


  $('.toggle-btn').on('click touch', function () {
    if (isAvailMenuBtn) {
      isAvailMenuBtn = false;

      if ($(this).hasClass('is-open')) {
        //閉じる
        $('body').removeClass('nav-open').addClass('nav-close');
        $('.menu-layer').fadeOut(800, function () {
          $('.menu-layer').remove(); // スクロールの有効化

          $('body,html,.site,.main-navigation,.pagetop,.toggle-btn').removeAttr('style');
          window.removeEventListener('touchmove', movefun, {
            passive: false
          });
          isAvailMenuBtn = true;
        }); // if($('body').hasClass('home')){
        //   $('.sec-secret .outer-box').removeAttr('style');
        // }
      } else {
        //開く
        $('body').removeClass('nav-close').addClass('nav-open');
        var paddingR = winW - document.body.clientWidth; // スクロールの無効化

        $('body,html').css('overflow', 'hidden');

        if (paddingR > 0) {
          $('body').css('padding-right', paddingR);
          var pagetopRight = $('.pagetop').css('right');
          pagetopRight = parseInt(pagetopRight);
          $('.pagetop').css('right', pagetopRight + paddingR + 'px');
          var toggleRight = $('.toggle-btn').css('right');
          toggleRight = parseInt(toggleRight);
          $('.toggle-btn').css('right', toggleRight + paddingR + 'px');
        }

        window.addEventListener('touchmove', movefun, {
          passive: false
        });
        beforTopPos = topPos;
        $('.site').append('<div class="menu-layer"></div>');
        $('.menu-layer').hide();
        $('.menu-layer').fadeIn(300, function () {
          isAvailMenuBtn = true;
        });

        if ($('body').hasClass('home')) {//$('.sec-secret .outer-box').width(winW);
        }
      }

      $(this).toggleClass('is-open');
    }
  });
  $('.close-btn').on(hitEvent, function () {
    $('.toggle-btn').click(); //擬似クリック
  }); //スクロール

  var ptBTN = $('.pagetop');
  var appearPoint;
  appearPointF(); //var appearPoint = winH / 3;

  var adjust, absoluteBottom, fixedBottom, fixedPoint, beforePos;
  absoluteBottom = 100;
  $(window).on('scroll', function () {
    beforePos = topPos;
    topPos = $(window).scrollTop();

    if (topPos < appearPoint && !ptBTN.hasClass('fadeOut')) {
      ptBTN.addClass('fadeOut');
      $('.logo-small').addClass('fadeOut');
      $('.nav-footer').addClass('fadeOut');
    }

    if (topPos > appearPoint && ptBTN.hasClass('fadeOut')) {
      ptBTN.removeClass('fadeOut');
      $('.logo-small').removeClass('fadeOut');
      $('.nav-footer').removeClass('fadeOut');
    }

    if ($('body').hasClass('home')) {
      if (topPos > winH && isParticle) {
        pJSDom[0].pJS.particles.move.enable = false;
        isParticle = false;
      }

      if (topPos < winH && !isParticle) {
        pJSDom[0].pJS.particles.move.enable = true;
        pJSDom[0].pJS.fn.particlesRefresh();
        isParticle = true;
      }
    }

    isScrolling = true;
    setTimeout(function () {
      isScrolling = false;
    }, 200);
    currentCheck();
  });

  function appearPointF() {
    if (is_fullmode) {
      appearPoint = $('.site-header .outer-box').height();
    } else {
      appearPoint = winH;
    }
  }
  /*smoothScroll*/


  var v_b = 0;
  var anchorName = '';
  var scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
    customEasing: function customEasing(time) {
      //easeOutExpo
      var t = time; // : 時間(進行度)

      var b = 0; //: 開始の値(開始時の座標やスケールなど)

      var c = 1; //: 開始と終了の値の差分

      var d = 1; //: Tween(トゥイーン)の合計時間

      var v = c * (-Math.pow(2, -10 * t / d) + 1) + b;

      if (v_b == v) {
        v = 0;
      }

      v_b = v;
      return v;
    },
    updateURL: false,
    offset: function offset(anchor, toggle) {
      var offset = 0;
      anchorName = toggle.getAttribute('href');

      if (is_fullmode) {
        offset = 0;
      } else {
        if (anchorName == '#page') {
          offset = 0;
        } else {
          offset = 56;
        }
      }

      return offset;
    }
  }); // function scrollStopEv(){
  //   if(anchorName == '#page' && isSP){
  //
  //     $('body, html').animate( {scrollTop: 0}, 'fast' ,function(){console.log('scrollStopEv');});
  //   }
  // }
  // document.addEventListener('scrollStop', scrollStopEv, false);

  Delighters.config({
    start: 0.85,
    end: 1
  }); // ナビゲーションのリンクを指定

  var navLink = $('.nav-top ul li a'); // 各コンテンツのページ上部からの開始位置と終了位置を配列に格納しておく

  var contentsArr = new Array();

  function contentsPosition() {
    for (var i = 0; i < navLink.length; i++) {
      // コンテンツのIDを取得
      var targetContents = navLink.eq(i).attr('href'); // ページ内リンクでないナビゲーションが含まれている場合は除外する

      if (targetContents.charAt(0) == '#') {
        // ページ上部からコンテンツの開始位置までの距離を取得
        var targetContentsTop = $(targetContents).offset().top - 5; // ページ上部からコンテンツの終了位置までの距離を取得

        var targetContentsBottom = targetContentsTop + $(targetContents).outerHeight(true) - 1; // ページ上部からコンテンツの開始位置までの距離を取得

        var targetContentsTop_down = $(targetContents).offset().top - $(targetContents).outerHeight(true) / 2; // ページ上部からコンテンツの終了位置までの距離を取得

        var targetContentsBottom_down = targetContentsTop + $(targetContents).outerHeight(true) / 2 - 1; // 配列に格納

        contentsArr[i] = [targetContentsTop, targetContentsBottom, targetContentsTop_down, targetContentsBottom_down];
      }
    }

    ;
  }

  contentsPosition(); // 現在地をチェックする

  function currentCheck() {
    //方向
    var direction = topPos > beforePos ? 'up' : 'down';

    for (var i = 0; i < contentsArr.length; i++) {
      // 現在のスクロール位置が、配列に格納した開始位置と終了位置の間にあるものを調べる
      if (direction == 'up') {
        if (contentsArr[i][0] <= topPos && contentsArr[i][1] >= topPos) {
          // 開始位置と終了位置の間にある場合、ナビゲーションにclass="current"をつける
          navLink.removeClass('current');
          navLink.eq(i).addClass('current');
          i == contentsArr.length;
        }
      } else {
        //down
        if (contentsArr[i][2] <= topPos && contentsArr[i][3] >= topPos) {
          navLink.removeClass('current');
          navLink.eq(i).addClass('current');
          i == contentsArr.length;
        }
      }
    }

    ;
  } //サイドバー


  $('.sidebar-right').hover(function () {
    //on
    if ($('body').hasClass('home')) {
      $(this).addClass('hover');
    } else if (!$('.nav-footer').hasClass('fadeOut')) {
      $(this).addClass('hover');
    }
  }, function () {
    //off
    $(this).removeClass('hover');
  }); //同意するにチェックでsubmitできるようにする

  if ($('#agree-1').prop('checked') == false) {
    $('.to-confirm').attr('disabled', 'disabled');
  }

  $('#agree-1').click(function () {
    if ($(this).prop('checked') == false) {
      $('.to-confirm').attr('disabled', 'disabled');
    } else {
      $('.to-confirm').removeAttr('disabled');
    }
  }); //flipsnap.js：トップページ

  function setFlipsnap() {
    if ($('body').hasClass('home')) {
      $('#case .flip-box ul').removeAttr('style');

      if (winW < 769) {
        var boxW = Math.round($('#case .flip-box ul').width());
        var articleW = $('#case .flip-box li').outerWidth();
        var margin = (boxW - articleW * 3) / 2;
        var distance_ = articleW + margin;
        Flipsnap('#case .flip-box ul', {
          distance: distance_
        });
      }
    }
  } //setFlipsnap();
  //backgroundパララクス


  if (!isSP) {
    $(window).stellar({
      horizontalScrolling: false,
      verticalOffset: 0,
      horizontalOffset: 0,
      responsive: true
    });
  } //パーティクル　設定ファイル読み込み：トップページ


  if ($('body').hasClass('home')) {
    if (isSP) {
      $.getJSON("/wp-content/themes/isharetech/js/particlesjs-config_sp.json", function (json) {
        particlesJS('particles-stage', json);
        isParticle = true;
      });
      $('#hero .outer-box').height($(window).height());
    } else {
      $.getJSON("/wp-content/themes/isharetech/js/particlesjs-config_pc.json", function (json) {
        particlesJS('particles-stage', json);
        isParticle = true;
      });
    }
  } //読み込み完了後


  $(window).on('load', function () {
    contentsPosition();
    currentCheck();
  }); //リサイズ

  window.addEventListener('resize', resizeHandler);

  function resizeHandler() {
    function delayed() {
      resize();
      resizeTimeout = null;
    }

    if (typeof resizeTimeout != 'undefined') {
      clearTimeout(resizeTimeout);
    }

    resizeTimeout = setTimeout(delayed, 300);
    $('body').addClass('transition-none');
  }

  function resize() {
    var beforeWin = winW;
    winW = window.innerWidth;
    winH = $(window).height();

    if (winW > 768) {
      //PC
      is_fullmode = true;
      contentsPosition();

      if (beforeWin < 768) {}
    } else {
      //SP
      is_fullmode = false;
      if (isSP) $('#hero .outer-box').animate({
        height: window.innerHeight
      }, 400);

      if (beforeWin > 768) {}
    }

    $('body').removeClass('transition-none');

    if (beforeWin != winW) {
      //setFlipsnap();
      appearPointF();
    }
  } // END resizeHandler

})(jQuery); // Fully reference jQuery after this point.